export const tableMixin = {
    methods: {
        getValue(entity, key) {
            if (key.indexOf('.') === -1) {
                return entity[key];
            }

            const parts = key.split('.');
            let value = entity;
            for (const part of parts) {
                if (value === null) {
                    continue;
                }
                // eslint-disable-next-line no-prototype-builtins
                value = value.hasOwnProperty(part) ? value[part] : null;
            }
            return value;
        }
    }
}
