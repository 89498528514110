<template>
  <Dialog v-model:visible="visible" :style="{width: '450px'}" header="Country details" :modal="true" class="grid p-fluid">
    <div class="col-12 md:col-12">
      <div class="grid">
        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
          <label for="name">Name</label>
          <InputText id="name" v-model.trim="entityData.name" required="true" autofocus :class="{'p-invalid': submitted && !entityData.name}" />
          <small class="p-invalid" v-if="submitted && !entityData.name">Name is required.</small>
        </div>
      </div>

      <div class="grid">
        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
          <label for="code">Code</label>
          <InputText id="code" v-model.trim="entityData.code" required="true" autofocus :class="{'p-invalid': submitted && !entityData.code}" />
          <small class="p-invalid" v-if="submitted && !entityData.code">Code is required.</small>
        </div>
      </div>

      <div class="grid">
        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
          <label for="iso2">Iso 2</label>
          <InputText id="iso2" v-model.trim="entityData.iso2" autofocus/>
        </div>
      </div>

      <div class="grid">
        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
          <label for="iso3">Iso3</label>
          <InputText id="iso3" v-model.trim="entityData.iso3" autofocus/>
        </div>
      </div>

      <div class="grid">
        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
          <label for="phone_prefix">Phone prefix</label>
          <InputText id="phone_prefix" v-model.trim="entityData.phone_prefix" autofocus/>
        </div>
      </div>
    </div>

    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="$emit('hideDialog')"/>
      <Button label="Save" icon="pi pi-check" class="p-button-text" @click="save" />
    </template>
  </Dialog>
</template>

<script>

import CountryService from "@/service/CountryService";

export default {
  name: "CountryForm",
  emits: ['hideDialog','saved'],
  props: {
    entity: Object,
    dialog: Boolean
  },
  entityService: null,

  computed: {
    visible: {
      get() { return this.dialog },
      set(visible) {this.$emit('hideDialog', visible)}
    },
    entityData: {
      get() { return this.entity },
    }
  },

  created() {
    this.entityService = new CountryService();
  },

  data() {
    return {
      submitted: false,
      inputTypes: [],
    }
  },

  methods: {
    save() {
      this.submitted = true;

      if (this.entity.id) {
        this.entityService.update(this.entityData).then(response => {
          this.$toast.add({severity:'success', summary: 'Success', detail: 'Country updated', life: 3000});
          this.$emit('saved', response);
        });
      } else {
        this.entityService.create(this.entityData).then(response => {
          this.$toast.add({severity:'success', summary: 'Success', detail: 'Country created', life: 3000});
          this.$emit('saved', response);
        });
      }
    },
  }
}
</script>

<style scoped>

</style>
